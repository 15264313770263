<template>
    <modal ref="modalCrearProyecto" :titulo="titulo" icon="project" @guardar="guardar">
        <ValidationObserver ref="validacion">
            <div class="row mx-0 mb-5">
                <div class="col-12 px-5 my-2">
                    <label class="text-muted2 f-15 px-2">Nombre del Proyecto</label>
                    <ValidationProvider v-slot="{errors}" rules="required|max:40" name="nombre">
                        <el-input v-model="model.nombre" class="w-100" maxlength="41" />
                        <p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
                    </ValidationProvider>
                </div>
                <div class="col-12 px-5 my-2">
                    <label class="text-muted2 f-15 px-2">Descripción</label>
                    <div style="width:391px;">
                        <ValidationProvider v-slot="{errors}" rules="required|max:1500" name="descripción">
                            <el-input v-model="model.descripcion" class="w-100" type="textarea" :rows="3" placeholder="Digite una descripción" maxlength="1501" />
                            <p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
                        </ValidationProvider>
                    </div>
                </div>
                <div class="col-12 px-5 d-flex justify-center py-4">
                    <el-radio-group v-model="model.tipo" :disabled="modoEditar">
                        <el-radio :label="1">
                            Tipo masivo
                        </el-radio>
                        <el-radio :label="2">
                            Tipo VIP
                        </el-radio>
                    </el-radio-group>
                </div>
                <div class="col-12 px-5">
                    <div style="width:391px;">
                        <label class="text-muted2 f-15 px-2"> Base de Datos </label>
                        <ValidationProvider v-slot="{errors}" rules="required|max:30|espacios" name="base de datos">
                            <el-input v-model="model.base_datos" class="w-100" maxlength="31" :disabled="modoEditar" />
                            <p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
                        </ValidationProvider>
                    </div>
                </div>
                <div class="col-12 my-2 px-5 d-flex align-items-center">
                    <div style="width:277px;">
                        <label class="text-muted2 f-15 px-2"> Dominio</label>
                        <ValidationProvider v-slot="{errors}" rules="required|max:40|espacios" name="dominio">
                            <el-input v-model="model.dominio" class="w-100" maxlength="41" :disabled="modoEditar" />
                            <p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
                        </ValidationProvider>
                    </div>
                    <p class=" mx-3 mt-4 f-14 text-muted2">
                        /tienda.leeche.app
                    </p>
                </div>
            </div>
        </ValidationObserver>
        <div class="row mx-0 px-5 mt-5">
            <p class="f-600 f-18">
                Prefijos
            </p>
        </div>
        <ValidationObserver ref="validIdentificador">
            <div class="row mx-0 mb-5">
                <div class="col-9">
                    <ValidationProvider v-slot="{errors}" :rules="{required: true, min:4, duplicado:prefijosGuardados, sinGuion:prefijo}" name="prefijo">
                        <el-input v-model="prefijo" class="w-100" maxlength="21" />
                        <p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
                    </ValidationProvider>
                </div>
                <div class="col-3">
                    <el-tooltip class="item" effect="light" content="Crear prefijo" placement="bottom">
                        <div class="bg-grr-general cr-pointer d-middle-center br-10" style="width:44px;height:44px;">
                            <i class="icon-plus f-20 text-white" @click="agregarIdentificador" />
                        </div>
                    </el-tooltip>
                </div>
            </div>
        </ValidationObserver>
        <div class="row mx-1 px-5 mt-2">
            <p class="f-600 f-18">
                Lista de prefijos
            </p>
        </div>
        <itemIdentificador v-for="(info, idx) in lista" :key="idx" :info="info" :index="idx" :editar="modoEditar" :can-eliminar="lista.length >= 1" @eliminar="eliminarVirtual" @editar="recargaVirtual" />
    </modal>
</template>
<script>
import Proyectos from '~/services/proyectos/proyectos'
import { extend } from 'vee-validate'
extend('espacios', {
    validate(value){
        var result = /^\S*$/u.test(value);
        return result;
    },
    params: ['length'],
    message: 'EL campo {_field_} no puede llevar espacios en blanco.'
});
extend('sinGuion', {
    validate(value){
        var result = /-/.test(value);
        return !result;
    },
    params: ['length'],
    message: 'EL campo {_field_} no puede llevar guion (-)'
});


extend('duplicado', {
    validate(value, preGuardados){
        let existe = false;
        preGuardados.forEach(pre =>{
            if(_.toLower(value).normalize("NFD").replace(/[\u0300-\u036f]/g, "") == _.toLower(pre.prefijo).normalize("NFD").replace(/[\u0300-\u036f]/g, "")){
                existe = true;
            }
        });
        if(existe){
            return false
        }else{
            return true
        }
    },
    message:
    "Ya existe otro prefijo con el mismo nombre."
});

export default {
    components:{
        itemIdentificador: ()=> import('../components/itemIdentificador')
    },
    data(){
        return{
            modoEditar: false,
            model:{
                id: null,
                nombre: null,
                tipo: 1,
                estado:1,
                descripcion: null,
                base_datos: null,
                dominio: null,
                bucket_s3: null,
                lista_prefijos: []
            },
            titulo: 'Crear Proyecto',
            id_proyecto: parseInt(this.$route.params.id_proyecto) || null,
            prefijo: null,
            lista: [],
            prefijosGuardados: []
        }
    },
    methods: {
        toggle(info){
            this.limpiar()
            if(info !== null){
                this.titulo =' Editar Proyecto'
                this.modoEditar = true
                this.model.id = info.id
                this.model.nombre = info.nombre
                this.model.tipo = info.tipo
                this.model.estado = info.estado
                this.model.descripcion = info.descripcion
                this.model.base_datos = info.base_datos
                this.model.dominio = info.dominio
                this.lista = info.prefijos
            }
            this.listarPrefijosParaValidar()
            this.$refs.modalCrearProyecto.toggle();
        },
        async guardar(){
            try {
                const valid = await this.$refs.validacion.validate()
                if(!valid){
                    this.notificacion('Mensaje', 'campos obligatorios', 'warning')
                    return false
                }
                if(this.modoEditar){
                    const {data} = await Proyectos.editarProyecto(this.model)
                    this.notificacion('Mensaje', 'Proyecto editado', 'success')
                    this.$emit('actualizar')
                }else{
                    this.model.lista_prefijos=  this.lista
                    if(this.model.lista_prefijos.length <= 0){
                        this.notificacion('Mensaje', 'Necesita por lo menos un prefijo', 'warning')
                        return false
                    }
                    this.model.bucket_s3 = this.model.base_datos
                   
                    const {data} = await Proyectos.crearProyecto(this.model)
                    this.notificacion('Mensaje', 'Proyecto creado', 'success')
                    this.$emit('crear')
                }
        
                this.$refs.modalCrearProyecto.toggle();

            } catch (e){
                this.error_catch(e)
            }
        },
        async listarPrefijosParaValidar(){
            try {
                const {data} = await Proyectos.prefijosGuardados()
                this.prefijosGuardados = data.prefijos
            } catch (e){
                this.error_catch(e)
            }

        },
        limpiar(){
            this.model={
                id: null,
                nombre: null,
                tipo: 1,
                estado:1,
                descripcion: null,
                base_datos: null,
                dominio: null,
                bucket_s3: null,
                lista_prefijos: []
            }
            this.modoEditar = false
            this.lista = []
            this.$refs.validacion.reset()

        },
        async listarIdentificadores(){
            try {
                const { data } = await Produccion.consultarIdentificadores(this.id_tienda)
                this.lista = data.identificadores
            } catch (error){
                this.error_catch(error)
            }
        },
        async agregarIdentificador(){
            try {
                const valid = await this.$refs.validIdentificador.validate()
                if(!valid){
                    this.notificacion('Mensaje', 'campos obligatorios', 'warning')
                    return false
                }

                if(this.modoEditar){
                    const {data} = await Proyectos.agregarPrefijo({id_proyecto: this.model.id, prefijo: this.prefijo})
                    this.lista.push(data.prefijo)
                    this.notificacion('Mensaje', 'Prefijo agregado', 'success')
                    this.prefijo = null
                    this.$refs.validIdentificador.reset()
                    return false
                }
                const enviar = {
                    id: 0,
                    id_proyecto: this.id_proyecto,
                    prefijo: this.prefijo
                }
                this.lista.push(enviar)
                this.prefijo = null
                this.$refs.validIdentificador.reset()
              

            } catch (e){
                this.error_catch(e)
            }

        },
        recargaVirtual(newItem){
            const newData = { ...this.lista, ...newItem}

        },
        eliminarVirtual(indice){
            this.lista = this.lista.filter((item, i) => i !== indice);
        }
    }
}
</script>